require("slick-carousel");

$(document).ready(() => {

  $('.slick').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: false
  });

  if($('.reveal').length){ 
    $('.reveal-trigger').click(function() {
      $('.reveal').foundation('open');
      $('.slick')[0].slick.setPosition()
      $('.slick').slick('slickGoTo',$(this).attr('data-slide-number'));
    })
    
    $('.reveal').on('open.zf.reveal', function(e){
      document.activeElement.blur();
      $('.slick-next.slick-arrow').focus();
    });
    
  }

})