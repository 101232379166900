document.addEventListener("DOMContentLoaded", () => {

  if ( typeof stripe !== 'undefined' ) {

    const submitButton = document.querySelector('#payment-form input.button');
    const form = document.getElementById('payment-form');
    const errorElement = document.getElementById('card-errors');
    const acceptCheckbox = document.getElementById('accept');

    const options = {
      clientSecret: submitButton.dataset.secret,
    };
    const elements = stripe.elements(options);

    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        // Add your base input styles here. For example:
        padding: 8,
        backgroundColor: "#fefefe",
        fontFamily: "inherit",
        fontSize: "17px",
        fontWeight: "normal",
        lineHeight: "1.5",
        color: "#000"
      },
    };

    const paymentElement = elements.create("payment");
    paymentElement.mount("#payment-element");

    const paymentForm = document.getElementById('payment-form');
    if ( paymentForm ) {
      paymentForm.addEventListener('submit', async (e) => {
        e.preventDefault();
        errorElement.textContent = '';
        const {error} = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: form.action+'/pay',
          },
          // Uncomment below if you only want redirect for redirect-based payments
          // redirect: 'if_required',
        });
        if ( error ) {
          errorElement.textContent = error.message;
          errorElement.style.color = 'red';
          acceptCheckbox.checked = false;
        }
      });
    }

    if ( acceptCheckbox && submitButton ) {
      acceptCheckbox.addEventListener('change', (e) => {
        submitButton.disabled = !e.target.checked;
      });
    }

  }

});
