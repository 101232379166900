document.addEventListener("DOMContentLoaded", () => {
  const entry_venue_address = document.getElementById('entry_venue_address_attributes_continent');
  if ( entry_venue_address ) {
    entry_venue_address.addEventListener('change', () => {
      region_category_update(true);
      // hideRegionCategories();
    })
    region_category_update(false);
    // hideRegionCategories();
  }
  const venue_types = document.querySelectorAll('[type=radio][name="entry[venue_type]"]');
  venue_types.forEach(venue_type=>{
    venue_type.addEventListener('change', () => {
      region_category_update(true);
      // hideRegionCategories();
    });
  });
})

function hideRegionCategories() {
  let venueTypeInput = document.querySelector('[type=radio][name="entry[venue_type]"]:checked');
  if ( venueTypeInput ) {
    let typeToHide = 'restaurant';
    if ( venueTypeInput.id == 'entry_venue_type_restaurant' ) {
      typeToHide = 'bar';
    }
    let expression = `${typeToHide}$`;
    let regexp = new RegExp(expression,'i');
    const allVisibleLabels = document.querySelectorAll('.categories-by-region label:not(.hide)');
    allVisibleLabels.forEach(label => {
      if ( regexp.test(label.innerText) ) {
        label.classList.add('hide');
        label.querySelector('input').checked = false;
      }
      else {
        label.classList.remove('hide');
      }
    });
  }
}

function region_category_update(clear_values){
  const location = document.getElementById('entry_venue_address_attributes_continent').value
  if(location){
    document.querySelectorAll('.categories-by-region').forEach(element => {
      element.classList.remove('hide')
    })
  }

  if(clear_values){
    document.querySelectorAll('.categories-by-region input').forEach(element => {
      element.checked = false
      triggerEvent(element,'change')
    })
    
    $('.categories-by-region input').trigger('change')
  }

  // document.querySelectorAll('.categories-by-region label').forEach(element => {
  //   element.classList.add('hide')
  // })
  
  // document.querySelectorAll('.categories-by-region label[data-continent="'+location+'"]').forEach(element => {
  //   element.classList.remove('hide')
  // })

  // if ( location == "UK" ) {
  //   document.querySelectorAll('.categories-by-region label[data-continent="Europe"]').forEach(element => {
  //     element.classList.remove('hide')
  //   })
  // }
}

function triggerEvent(el, type){
  if ('createEvent' in document) {
    var e = document.createEvent('HTMLEvents');
    e.initEvent(type, false, true);
    el.dispatchEvent(e);
   }
}