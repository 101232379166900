import { Accordion, Reveal } from 'foundation-sites';

$(document).ready(() => {
  $(document).foundation()

  $('.toggle-all-accordion').click((e) => {
    e.preventDefault()
    if($(e.target).hasClass('open')){
      $('.accordion').foundation('up', $('.accordion .accordion-content'))
      $(e.target).html('Expand All')
    }else{
      $('.accordion').foundation('down', $('.accordion .accordion-content'))
      $(e.target).html('Collapse All')
    }

    $(e.target).toggleClass('open')
      
    return false
  })
})