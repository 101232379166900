document.addEventListener("DOMContentLoaded", () => {
  $('.copy_address').on('click', (e) => {

    e.preventDefault();
    
    const source = $(e.target).attr('data-source')
    const target = $(e.target).attr('data-target')

    $('.'+source).each((i,v) => {
      const targetName = $(v).attr('name').replace(source,target)
      $('.'+target+'[name="'+targetName+'"]').val($(v).val())
    })
    return false;
  })
  // $('[data-video="true"]').on('blur', (e) => {
  //   value = e.target.value;
  //   regExp = /(?<=src=").*?(?=[\?"])/
  //   if ( regExp.test(value) ) {
  //     e.target.value = regExp.exec(value);
  //   }
  // });
})