
export class EntryFeeCalc{
  constructor(selector,checkbox_class) {
    this.selector = selector
    this.checkbox_class = checkbox_class

    const calcCost = () => {
      const cats_entered = document.querySelectorAll(checkbox_class+':checked').length
      if(cats_entered <= 0){
        document.querySelectorAll(selector).forEach(element => {
          element.classList.add('hide')
        })
      }else{
        document.querySelectorAll(selector).forEach(element => {
          const base_cost = parseInt(element.getAttribute('data-base-cost'))
          const additional_category_cost = parseInt(element.getAttribute('data-additional-category-cost'))
          if(cats_entered>1)
            element.querySelector('span').innerHTML ='£'+(base_cost+(additional_category_cost*(cats_entered-1)));
          else
            element.querySelector('span').innerHTML ='£'+(base_cost*cats_entered);
          element.classList.remove('hide')
        })
      }
      
    }

    document.querySelectorAll(this.checkbox_class).forEach(element => {
      element.addEventListener('change',() => {
        calcCost()
      })
    })
    calcCost()
  }
}