export default class LocationMap {
  constructor(element) {
    this.element = element;
    this.address1Field = element.parentNode.parentNode.querySelector(".address-1");
    this.cityField = element.parentNode.parentNode.querySelector(".city");
    this.countryField = element.parentNode.parentNode.querySelector(".country");
    this.postcodeField = element.parentNode.parentNode.querySelector(".postcode");
    this.latField = element.parentNode.querySelector(".location-lat");
    this.lonField = element.parentNode.querySelector(".location-lon");
    this.setButton = element.parentNode.parentNode.querySelector(".location-map-button");
    this.draggable = this.setButton ? true : false;

    this.map;
    this.marker;
    this.init();
  }
  init() {
    this.initMap();
    if (this.setButton) {
      this.setButton.addEventListener("click", this.setButtonClick.bind(this));
    }
  }
  setButtonClick() {
    //address lookup
    const geocoder = new google.maps.Geocoder();

    const address = [this.address1Field.value, this.cityField.value].join(",");

    const request = { address: address, componentRestrictions: {} };

    if (this.countryField.value) {
      request.componentRestrictions.country = this.countryField.value;
    }

    if (this.postcodeField.value) {
      request.componentRestrictions.postalCode = this.postcodeField.value;
    }

    geocoder.geocode(request).then((result) => {
      const { results } = result;
      this.map.setCenter(results[0].geometry.location);
      this.marker.setPosition(results[0].geometry.location);
      this.latField.value = results[0].geometry.location.lat();
      this.lonField.value = results[0].geometry.location.lng();
    });
  }
  async initMap() {
    const position = { lat: 51.50929859696815, lng: -0.122893867902258 };

    if (this.latField.value) {
      position.lat = parseFloat(this.latField.value);
    }
    if (this.lonField.value) {
      position.lng = parseFloat(this.lonField.value);
    }

    const { Map } = await google.maps.importLibrary("maps");
    const { Marker } = await google.maps.importLibrary("marker");

    // The map, centered at Uluru
    this.map = new Map(this.element, {
      zoom: 15,
      center: position,
    });

    // The marker, positioned at Uluru
    this.marker = new Marker({
      map: this.map,
      position: position,
      draggable: this.draggable,
    });

    this.marker.addListener("dragend", (event) => {
      const position = this.marker.position;
      this.latField.value = position.lat();
      this.lonField.value = position.lng();
    });
  }
}
