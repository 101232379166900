class FormValidation {

  constructor() {
    let validateableFields = document.querySelectorAll('[pattern]');
    validateableFields.forEach( field => {
      field.addEventListener('blur', e => {
        this.removeError(field);
        if ( field.checkValidity() === false ) {
          this.showError(field);
        }
      });
      field.addEventListener('keyup', e => {
        this.removeError(field);
      });
    });
  }

  showError(field) {
    let label = document.querySelector(`label[for="${field.id}"]`);
    if ( label ) {
      let labelParent = label.parentElement;
      if ( labelParent ) label.classList.add('field_with_errors');
    }
    let fieldParent = field.parentElement;
    if ( fieldParent ) fieldParent.classList.add('field_with_errors');
  }

  removeError(field) {
    let label = document.querySelector(`label[for="${field.id}"]`);
    if ( label ) {
      let labelParent = label.parentElement;
      if ( labelParent ) label.classList.remove('field_with_errors');
    }
    let fieldParent = field.parentElement;
    if ( fieldParent ) fieldParent.classList.remove('field_with_errors');
  }

}

let formValidation = new FormValidation();