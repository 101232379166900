export class CharacterCount {
  constructor(selector, warn = 10) {
    //add hint html
    document.querySelectorAll(selector).forEach((element) => {
      const limit = element.getAttribute("data-limit") ? parseInt(element.getAttribute("data-limit")) : 450;
      let hint = document.createElement("div");
      hint.classList.add("char-count-hint");
      hint.classList.add("text-right");
      hint.innerHTML = limit - element.value.length + " characters remaining";
      element.insertAdjacentElement("afterend", hint);

      element.addEventListener("keyup", (e) => {
        let remaining = limit - e.target.value.length;
        if (remaining <= warn) {
          e.target.previousElementSibling.classList.add("warn");
        } else {
          e.target.previousElementSibling.classList.remove("warn");
        }
        hint.innerHTML = remaining + " characters remaining";
      });
    });
  }
}
