import Sortable from 'sortablejs';
import Dropzone from 'dropzone';

Dropzone.options.imageDropzone = false;

document.addEventListener("DOMContentLoaded", () => {
  const sortableGridElement = document.getElementById('sortableGrid');
  const dropzoneElement = document.getElementById('image-dropzone');
  let mode; // save[images] || continue[summary]

  if ( sortableGridElement && dropzoneElement ) {

    let saveButton = document.querySelector('[name="save[images]"]');
    let continueButton = document.querySelector('[name="continue[summary]"]');
    
    // Sortable grid elements:
    const sortableGrid = new Sortable(sortableGridElement, {
      draggable: '.sortable-item',
      handle: ".sortable-item-handle",
      animation: 400,
      easing: "cubic-bezier(1, 0, 0, 1)",
      onChange: (e) => {
        // debugger;
      }
    });

    // Dropzone for new images:
    const dropzone = new Dropzone(dropzoneElement, {
      url: document.getElementById('entry-images').action+'/images',
      acceptedFiles: 'image/*',
      method: 'post',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      maxFileSize: 15,
      parallelUploads: 1,
      uploadMultiple: true,
      maxFiles: 10,
      acceptedFiles: 'image/*',
      autoQueue: true,
      autoProcessQueue: false,
      addRemoveLinks: false,
      thumbnailWidth: 260,
      thumbnailHeight: 260,
      previewTemplate: document.querySelector('#tpl').innerHTML
    });

    dropzone.on('addedfile', (file) => {
      document.querySelector('input[name="save[image_upload]"]').classList.remove('hide');
      saveButton.disabled = true;
      continueButton.disabled = true;
    });

    // Upload images || Save new order for images
    if(continueButton){
      continueButton.addEventListener('click', (e)=>{
        e.preventDefault();
        mode = 'continue[summary]';
        saveSortOrder();
        return false;
      });
    }

    if(saveButton){
      saveButton.addEventListener('click', (e)=>{
        e.preventDefault();
        let error_explanation = document.getElementById('error_explanation')
        if(error_explanation){
          error_explanation.classList.add('hide');
        }
        mode = 'save[images]';
        saveSortOrder();
        return false;
      });
    }

    const uploadImagesButton = document.querySelector('input[name="save[image_upload]"]');
    uploadImagesButton.addEventListener('click', (e)=>{
      e.preventDefault();
      mode = 'save[image_upload]';
      processData();
      document.querySelector('.uploaded-images').classList.remove('hide');
      return false;
    });

    dropzone.on('processingmultiple', (file) => {
      uploadImagesButton.disabled = true;
    });

    dropzone.on('success', (file, response) => {
      setTimeout( ()=>{
        appendImage(response);
        dropzone.removeFile(file);
        processData();
      }, 1500 );
    });

    dropzone.on('reset', () => {
      saveButton.disabled = false;
      continueButton.disabled = false;
    });

    const appendImage = (response) => {
      sortableGridElement.innerHTML += response.image;
    }

    const saveSortOrder = () => {
      const ids = sortableGrid.toArray();
      let positionField;
      let counter = 1;
      ids.forEach((id)=>{
        positionField = document.querySelector(`input[name="entry[images_attributes][${id}][position]"]`);
        if ( positionField ) {
          positionField.value = counter;
        }
        counter++;
      });
      const form = document.getElementById('entry-images');
      let modeInput = document.createElement('input');
      modeInput.setAttribute('type', 'hidden');
      modeInput.setAttribute('name', mode);
      modeInput.setAttribute('value', 'true');
      form.appendChild(modeInput);
      form.submit();
    }

    const processData = () => {
      if (dropzone.getQueuedFiles().length > 0) {
        dropzone.processQueue();
      }
      else {
        saveButton.disabled = false;
        continueButton.disabled = false;
      }
    };

    document.addEventListener('click', function (e) {
      if (e.target.matches('i.remove-image')) {
        e.preventDefault();
        e.target.parentElement.parentElement.parentElement.parentElement.classList.add('hide');
        e.target.parentElement.querySelector('.destroy').value = 1;
      }
      else if (e.target.matches('.primary-image-checkbox')) {
        let allCheckboxesForThisCategory = document.querySelectorAll(`[name="${e.target.name}"`);
        allCheckboxesForThisCategory.forEach( (chkbox) => {
          if ( chkbox != e.target ) chkbox.checked = false;
        });
      }
    }, false);

  }

});

