/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import "cocoon-js-vanilla";
import "../javascripts/foundation";
import "../javascripts/slick";
import "../javascripts/datepicker";
import "../javascripts/entry-product-form";
import "../javascripts/entry-images";
import "../javascripts/payment";
import "../javascripts/address-copy";
import "../javascripts/region-categories";
import "../javascripts/formValidation";
import LocationMap from "../javascripts/location-map";
import { CharacterCount } from "../javascripts/character-count";
import { EntryFeeCalc } from "../javascripts/entry-fee";

document.addEventListener("DOMContentLoaded", () => {
  new CharacterCount(".char-count");
  new EntryFeeCalc(".entry-fee", ".entry-category");

  $('form.edit_entry input[type="submit"]').click((e) => {
    //trigger HTML5 validation on all submits, unless it's a save only
    if (e.target.getAttribute("name") != "save") {
      var valid = document.querySelector("form.edit_entry").reportValidity();
      if (!valid) {
        e.preventDefault();
      }
    }
  });

  document.querySelectorAll(".location-map").forEach((locationMap) => {
    new LocationMap(locationMap);
  });

  if (document.getElementById("locations")) {
    document.getElementById("locations").addEventListener("cocoon:after-insert", function (e) {
      const mapContainer = e.detail[2].querySelector(".location-map");
      new LocationMap(mapContainer);
    });
  }

  $("#entry_venue_country").change(() => {
    $("#entry_venue_address_attributes_country").val($("#entry_venue_country").val());
    if (!$("body").hasClass("admin")) $("#entry_venue_address_attributes_country").attr("disabled", true);
  });
  $("#entry_venue_address_attributes_country").val($("#entry_venue_country").val());
  if (!$("body").hasClass("admin")) $("#entry_venue_address_attributes_country").attr("disabled", true);
});
