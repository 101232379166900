import datepicker from "js-datepicker";

document.addEventListener("DOMContentLoaded", function () {
  const entryYear = new Date().getFullYear() - 1;
  const a = document.body.classList.contains("admin");

  if (document.querySelectorAll("input.date").length) {
    document.querySelectorAll("input.date").forEach((element) => {
      const config = {
        formatter: (input, date, instance) => {
          const month = (date.getMonth() + 1).toString();
          const day = date.getDate().toString();
          input.value =
            date.getFullYear() +
            "-" +
            (month.length < 2 ? "0" + month : month) +
            "-" +
            (day.length < 2 ? "0" + day : day);
        },
      };

      if (element.classList.contains("opening-date")) {
        config = {
          ...formatter,
          startDate: new Date(entryYear, 0, 1),
          minDate: new Date(entryYear, 0, 1),
          maxDate: new Date(entryYear, 11, 31),
        };
      }

      const picker = datepicker(element, config);
      if (!a) {
        picker.setMax(new Date(entryYear + 1, 2, 31));
        picker.setMin(new Date(entryYear, 0, 1));
      }
    });
  }
});
