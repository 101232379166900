$(document).ready(() => {
  $('body').on('click', '.add_new_product', (e) => {addProduct(e)});
  $('body').on('click', '.remove_product', (e) => {removeProduct(e)});
})

function addProduct(e){
  e.preventDefault();    
  const template = $('.product-template').html().replace(/new_product_id/g,new Date().getTime());
  const lastSet = $('form fieldset.product').last();

  if(lastSet){
    lastSet.after(template)
  }else{
    $('form').prepend(template)      
  }

  setTimeout(() => { $('form .highlight-section').removeClass('highlight-section') },1000)

  return false;
}

function removeProduct(e){
  e.preventDefault();
  const target = e.target
  $(target).parent('fieldset.product').children('input').prop('required',false);
  $(target).parent('fieldset.product').children('select').prop('required',false);

  if($(target).parent('fieldset.product').hasClass('dynamic')){
    //no data persisted yet so just remove the markup    
    $(target).parent('fieldset.product').addClass('hide-section')
    setTimeout(() => { $(target).parent('fieldset.product').remove()},1000, target)
  }else{
    //mark existing stored record for delete
    $(target).parent('fieldset.product').addClass('hide-section')
    $(target).parent('fieldset.product').children('.destroy').val(1)
  }

  return false
}